var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c('basic-form',{attrs:{"title":_vm.title,"isLoading":_vm.loading},on:{"save":_vm.save,"update:isLoading":function($event){_vm.loading=$event},"update:is-loading":function($event){_vm.loading=$event}}},[_c('template',{slot:"fields"},[_c('div',{staticClass:"flex flex-col md:flex-row justify-center"},[_c('div',{staticClass:"w-full md:w-1/2 mr-3"},[_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('tournament')
                ? _vm.errors.first('tournament')
                : '',"variant":_vm.showValidation && _vm.errors.first('tournament') ? 'danger' : '',"label":'Torneo'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"tournament","id":"tournament","placeholder":"Ingrese la academia","autocomplete":"off","disabled":true,"type":"text","variant":_vm.showValidation && _vm.errors.first('tournament') ? 'danger' : ''},model:{value:(_vm.form.tournament.name),callback:function ($$v) {_vm.$set(_vm.form.tournament, "name", $$v)},expression:"form.tournament.name"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('name')
                ? _vm.errors.first('name')
                : '',"variant":_vm.showValidation && _vm.errors.first('name') ? 'danger' : '',"label":'Nombre'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:200'),expression:"'required|max:200'"}],attrs:{"data-vv-validate-on":"input","name":"name","id":"name","placeholder":"Ingrese el nombre del 11 ideal","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('name') ? 'danger' : ''},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1),_c('div',{staticClass:"w-full md:w-1/2 mx-1"},[_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('category')
                ? _vm.errors.first('category')
                : '',"variant":_vm.showValidation && _vm.errors.first('category') ? 'danger' : '',"label":'Categoría'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"category","id":"category","placeholder":"Ingrese la categoría","autocomplete":"off","disabled":true,"type":"text","variant":_vm.showValidation && _vm.errors.first('category') ? 'danger' : ''},model:{value:(_vm.form.category.name),callback:function ($$v) {_vm.$set(_vm.form.category, "name", $$v)},expression:"form.category.name"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('subcategory')
                ? _vm.errors.first('subcategory')
                : '',"variant":_vm.showValidation && _vm.errors.first('subcategory') ? 'danger' : '',"label":'Subcategoría'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":"subcategory","id":"subcategory","placeholder":"Ingrese la subcategoría","autocomplete":"off","disabled":true,"type":"text","variant":_vm.showValidation && _vm.errors.first('subcategory') ? 'danger' : ''},model:{value:(_vm.form.subcategory.name),callback:function ($$v) {_vm.$set(_vm.form.subcategory, "name", $$v)},expression:"form.subcategory.name"}})],1)],1)]),_c('br'),_vm._l((_vm.form.players),function(data,index){return _c('div',{key:'J-' + index,staticClass:"flex flex-col md:flex-row justify-center"},[_c('div',{staticClass:"w-full md:w-1/5"},[(index == 0)?_c('h1',[_vm._v("Arquero:")]):_vm._e(),(index == 1)?_c('h1',[_vm._v("Defensas:")]):_vm._e(),(index == 5)?_c('h1',[_vm._v("Mediocampistas:")]):_vm._e(),(index == 8)?_c('h1',[_vm._v("Delanteros:")]):_vm._e()]),_c('div',{staticClass:"w-full md:w-2/5 mr-3"},[_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('academy' + index)
                ? _vm.errors.first('academy' + index)
                : '',"variant":_vm.showValidation && _vm.errors.first('academy' + index)
                ? 'danger'
                : '',"label":'Academia'}},[_c('t-rich-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":'academy' + index,"id":'academy' + index,"placeholder":"Seleccione la academia","disabled":_vm.loading,"noResultsText":"No se encontraron resultados","searchBoxPlaceholder":"Busca...","options":_vm.academies,"textAttribute":"name","valueAttribute":"index","multiple":false,"closeOnSelect":true,"clearable":false,"variant":_vm.showValidation && _vm.errors.first('academy' + index)
                  ? 'danger'
                  : ''},on:{"change":function($event){return _vm.getPlayers(index)}},model:{value:(data.id_academy),callback:function ($$v) {_vm.$set(data, "id_academy", $$v)},expression:"data.id_academy"}})],1)],1),_c('div',{staticClass:"w-full md:w-2/5 mx-1"},[_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('player' + index)
                ? _vm.errors.first('player' + index)
                : '',"variant":_vm.showValidation && _vm.errors.first('player' + index) ? 'danger' : '',"label":'Jugador'}},[_c('t-rich-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"data-vv-validate-on":"input","name":'player' + index,"id":'player' + index,"placeholder":"Seleccione el jugador","disabled":_vm.loading,"noResultsText":"No se encontraron resultados","searchBoxPlaceholder":"Busca...","options":_vm.players[index],"valueAttribute":"index","textAttribute":"name","multiple":false,"closeOnSelect":true,"clearable":false,"variant":_vm.showValidation && _vm.errors.first('player' + index)
                  ? 'danger'
                  : ''},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                  var className = ref.className;
                  var option = ref.option;
return [_c('div',{class:className},[_c('span',{staticClass:"flex-shrink-0"},[_c('img',{staticClass:"rounded-full",staticStyle:{"height":"50px","width":"50px","object-fit":"cover"},attrs:{"src":option.raw.image}})]),_c('div',{staticClass:"flex flex-col w-full ml-2 text-gray-800"},[_vm._v(" "+_vm._s(option.text)+" ")])])]}}],null,true),model:{value:(data.id_player),callback:function ($$v) {_vm.$set(data, "id_player", $$v)},expression:"data.id_player"}})],1)],1)])})],2)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }