<template>
  <div class="px-5">
    <basic-form :title="title" @save="save" :isLoading.sync="loading">
      <template slot="fields">
        <div class="flex flex-col md:flex-row justify-center">
          <div class="w-full md:w-1/2 mr-3">
            <t-input-group
              :feedback="
                showValidation && errors.first('tournament')
                  ? errors.first('tournament')
                  : ''
              "
              :variant="
                showValidation && errors.first('tournament') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Torneo'"
            >
              <t-input
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.tournament.name"
                name="tournament"
                id="tournament"
                placeholder="Ingrese la academia"
                autocomplete="off"
                :disabled="true"
                type="text"
                :variant="
                  showValidation && errors.first('tournament') ? 'danger' : ''
                "
              ></t-input>
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('name')
                  ? errors.first('name')
                  : ''
              "
              :variant="showValidation && errors.first('name') ? 'danger' : ''"
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Nombre'"
            >
              <t-input
                v-validate="'required|max:200'"
                data-vv-validate-on="input"
                v-model="form.name"
                name="name"
                id="name"
                placeholder="Ingrese el nombre del 11 ideal"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('name') ? 'danger' : ''
                "
              />
            </t-input-group>
          </div>

          <div class="w-full md:w-1/2 mx-1">
            <t-input-group
              :feedback="
                showValidation && errors.first('category')
                  ? errors.first('category')
                  : ''
              "
              :variant="
                showValidation && errors.first('category') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Categoría'"
            >
              <t-input
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.category.name"
                name="category"
                id="category"
                placeholder="Ingrese la categoría"
                autocomplete="off"
                :disabled="true"
                type="text"
                :variant="
                  showValidation && errors.first('category') ? 'danger' : ''
                "
              ></t-input>
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('subcategory')
                  ? errors.first('subcategory')
                  : ''
              "
              :variant="
                showValidation && errors.first('subcategory') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Subcategoría'"
            >
              <t-input
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="form.subcategory.name"
                name="subcategory"
                id="subcategory"
                placeholder="Ingrese la subcategoría"
                autocomplete="off"
                :disabled="true"
                type="text"
                :variant="
                  showValidation && errors.first('subcategory') ? 'danger' : ''
                "
              ></t-input>
            </t-input-group>
          </div>
        </div>

        <br />

        <div
          v-for="(data, index) of form.players"
          :key="'J-' + index"
          class="flex flex-col md:flex-row justify-center"
        >
          <div class="w-full md:w-1/5">
            <h1 v-if="index == 0">Arquero:</h1>
            <h1 v-if="index == 1">Defensas:</h1>
            <h1 v-if="index == 5">Mediocampistas:</h1>
            <h1 v-if="index == 8">Delanteros:</h1>
          </div>

          <div class="w-full md:w-2/5 mr-3">
            <t-input-group
              :feedback="
                showValidation && errors.first('academy' + index)
                  ? errors.first('academy' + index)
                  : ''
              "
              :variant="
                showValidation && errors.first('academy' + index)
                  ? 'danger'
                  : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Academia'"
            >
              <t-rich-select
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="data.id_academy"
                :name="'academy' + index"
                :id="'academy' + index"
                placeholder="Seleccione la academia"
                :disabled="loading"
                noResultsText="No se encontraron resultados"
                searchBoxPlaceholder="Busca..."
                :options="academies"
                textAttribute="name"
                valueAttribute="index"
                @change="getPlayers(index)"
                :multiple="false"
                :closeOnSelect="true"
                :clearable="false"
                :variant="
                  showValidation && errors.first('academy' + index)
                    ? 'danger'
                    : ''
                "
              ></t-rich-select>
            </t-input-group>
          </div>

          <div class="w-full md:w-2/5 mx-1">
            <t-input-group
              :feedback="
                showValidation && errors.first('player' + index)
                  ? errors.first('player' + index)
                  : ''
              "
              :variant="
                showValidation && errors.first('player' + index) ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Jugador'"
            >
              <t-rich-select
                v-validate="'required'"
                data-vv-validate-on="input"
                v-model="data.id_player"
                :name="'player' + index"
                :id="'player' + index"
                placeholder="Seleccione el jugador"
                :disabled="loading"
                noResultsText="No se encontraron resultados"
                searchBoxPlaceholder="Busca..."
                :options="players[index]"
                valueAttribute="index"
                textAttribute="name"
                :multiple="false"
                :closeOnSelect="true"
                :clearable="false"
                :variant="
                  showValidation && errors.first('player' + index)
                    ? 'danger'
                    : ''
                "
              >
                <template slot="option" slot-scope="{ className, option }">
                  <div :class="className">
                    <span class="flex-shrink-0">
                      <img
                        class="rounded-full"
                        :src="option.raw.image"
                        style="height: 50px; width: 50px; object-fit: cover"
                      />
                    </span>
                    <div class="flex flex-col w-full ml-2 text-gray-800">
                      {{ option.text }}
                    </div>
                  </div>
                </template>
              </t-rich-select>
            </t-input-group>
          </div>
        </div>
      </template>
    </basic-form>
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
export default {
  components: {},
  props: {
    title: {
      type: String
    },
    deletable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      form: {
        name: null,
        id_tournament: null,
        id_category: null,
        id_subcategory: null,
        players: [
          {
            id_academy: null,
            id_player: null
          },
          {
            id_academy: null,
            id_player: null
          },
          {
            id_academy: null,
            id_player: null
          },
          {
            id_academy: null,
            id_player: null
          },
          {
            id_academy: null,
            id_player: null
          },
          {
            id_academy: null,
            id_player: null
          },
          {
            id_academy: null,
            id_player: null
          },
          {
            id_academy: null,
            id_player: null
          },
          {
            id_academy: null,
            id_player: null
          },
          {
            id_academy: null,
            id_player: null
          },
          {
            id_academy: null,
            id_player: null
          }
        ],
        tournament: { name: null },
        category: { name: null },
        subcategory: { name: null }
      },
      players: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: []
      },
      showValidation: false,
      visible: false
    }
  },
  computed: {
    academies() {
      return this.$store.getters["global/getItems"](
        "tournamentAcademy",
        "unpaginated_elements"
      ).map((el) => ({
        index: el.id_academy,
        name: el.academy.name
      }))
    },
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    getPlayers(index) {
      this.$store
        .dispatch("global/getItems", {
          module: "player",
          route: "player/all",
          noPaginate: true,
          isMaster: false,
          params: {
            active: 1,
            order_key: "jersey_number",
            order_value: "asc",
            verified: 1,
            id_academy: this.form.players[index].id_academy,
            id_category: this.form.id_category,
            id_subcategory: this.form.id_subcategory
          }
        })
        .then((response) => {
          this.players[String(index)] = response.data.map((el) => ({
            index: el.id,
            name: el.jersey_number + ". " + el.first_name + " " + el.last_name,
            image: el.url_profile_picture
          }))
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    toggleVisibility(visible) {
      this.$emit("toggleVisibility", visible)
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          this.$emit("save")
        }
      })
    }
  },
  created() {}
}
</script>
