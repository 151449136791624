<template>
  <div>
    <Form
      title="Añadir 11 ideal"
      ref="elevenForm"
      :loading.sync="loading"
      @save="save"
      @toggleVisibility="toggleVisibility"
    />
  </div>
</template>

<script>
const notify = () => import("@/utils/notify.js")
import Form from "./Form.vue"
export default {
  components: {
    Form
  },
  data() {
    return {
      form: {},
      loading: true,
      visible: false
    }
  },
  methods: {
    toggleVisibility(visible) {
      this.visible = visible
    },
    getItem() {
      this.$store
        .dispatch("global/getItem", {
          route: "/tournament_category",
          module: "tournamentCategory",
          id: this.$route.params.id2
        })
        .then((response) => {
          this.form = response.data
          this.$refs.elevenForm.form.id_tournament = this.form.id_tournament
          this.$refs.elevenForm.form.id_category = this.form.id_category
          this.$refs.elevenForm.form.id_subcategory = this.form.id_subcategory
          this.$refs.elevenForm.form.tournament = this.form.tournament
          this.$refs.elevenForm.form.category = this.form.category
          this.$refs.elevenForm.form.subcategory = this.form.subcategory
          this.$refs.elevenForm.visible = response.data && response.data.active === 1 ? true : false

          this.getAcademies()
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    getAcademies() {
      this.$store
        .dispatch("global/getItems", {
          module: 'tournamentAcademy',
          route: '/tournament_academy/all',
          noPaginate: true,
          isMaster: false,
          params: {
            active: 1,
            order_key: "id",
            order_value: "asc",
            verified: 1,
            id_tournament: this.form.id_tournament,
            id_category: this.form.id_category,
            id_subcategory: this.form.id_subcategory
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    save() {
      let form = this.$refs.elevenForm.form
      this.loading = true

      this.$store
        .dispatch("global/create", {
          payload: {
            id_tournament: this.$route.params.id,
            id_category: form.id_category,
            id_subcategory: form.id_subcategory,
            name: form.name,
            players: JSON.stringify(form.players)
          },
          route: "/eleven/save",
          module: "eleven"
        })
        .then((response) => {
          notify().then(({ notification }) => {
            notification("master", response.code, this.$snotify)
          })
          this.loading = false

          if ([200, 201].includes(response.code)) {
            this.$router.push("/torneos/"+this.$route.params.id+"/categorias/"+this.$route.params.id2+"/academias")
          }
        })
        .catch((error) => {
          this.loading = false
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    }
  },
  created() {
    this.getItem()
  }
}
</script>
